/* global statistics, Chart */
if( !window.statistics ) window.statistics = {};

(function() {

  let midSpace = String.fromCharCode(0x2006);
  let thinSpace = String.fromCharCode(0x2009);

  let metersSign = midSpace + 'м²';
  let percentSign = thinSpace + '%';

  function setUpDaysChart(data) {
    const dates = data.votesByDate.map(e => e.date);
    const surfaces = data.votesByDate.map(e => e.surface);
    const percents = surfaces.map(e => e / data.total.surface * 100);
    var runningSurface = 0;
    const runningSurfaces = surfaces.map(e => runningSurface += e);
    const runningPercents = runningSurfaces.map(e => e / data.total.surface * 100);
    const laggingPercents = [0].concat(runningPercents.slice(0,-1));
    dates.reverse(); percents.reverse(); runningPercents.reverse(); laggingPercents.reverse();

    const axisStep = 1;
    const axisLabelsStep = 5;
    const axisMax = Math.min(Math.ceil((Math.max.apply(null, runningPercents) * 2) / 10) * 10, 100);
    var gridColors = [];
    for(var i=0; i<=axisMax; i+=axisStep)
      gridColors.push( i % axisLabelsStep === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.03)' );

    $('#chart-days').attr("width", 1000 );
    $('#chart-days').attr("height", dates.length * 20 );
    $('#chart-days').css({width: '100%', height: 'auto'});

    new Chart($('#chart-days'), {
      type: 'horizontalBar',
      data: {
        labels: dates,
        datasets: [{
          data: laggingPercents,
          backgroundColor: 'rgba(0, 0, 100, 0.7)',
          borderColor: 'rgba(0, 0, 100, 1.0)',
          datalabels: { labels: { title: null, value: null } }
        }, {
          data: percents,
          backgroundColor: 'rgba(0, 100, 0, 0.7)',
          borderColor: 'rgba(0, 100, 0, 1.0)',
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function(value, context) {
              return runningPercents[context.dataIndex].toFixed(2) + percentSign + ' (' + thinSpace + '+' + thinSpace + value.toFixed(2) + percentSign + ')';
            },
            labels: {
              title: null,
              value: {
                color: '#555',
                font: { size: 14, weight: 'bold' }
              }
            }
          }
        }]
      },
      options: {
        responsive: false,
        tooltips: { enabled: false },
        elements: { rectangle: { borderWidth: 2 } },
        layout: { padding: { left: 0, right: 24, top: 0, bottom: 0 } },
        legend: { display: false },
        scales: {
          xAxes: [{
            stacked: true,
            type: 'linear',
            position: 'top',
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: true,
              drawBorder: true,
              lineWidth: 1,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(0, 0, 0, 0.3)',
              color: gridColors,
            },
            ticks: {
              maxTicksLimit: 100,
              stepSize: axisStep,
              maxRotation: 0,
              labelOffset: 5,
              autoSkip: false,
              min: 0, max: axisMax,
              fontColor: '#777',
              fontSize: 14,
              fontStyle: 'bold',
              callback: function(value /*, index, values*/) {
                return value % axisLabelsStep === 0 ? value + percentSign : '';
              }
            }
          }],
          yAxes: [{
            stacked: true,
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
              lineWidth: 1,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(0, 0, 0, 0.3)',
              color: 'rgba(0, 0, 0, 0)',
            },
            ticks: {
              fontColor: '#333',
              fontSize: 14,
              padding: -3,
              callback: function(value /*, index, values*/ ) {
                return new Date(value)
                    .toLocaleString('ru-Ru', {weekday: 'short'})
                    .toLowerCase() +
                  ", " + value.split("-").slice(1).reverse().join(".");
              }
            }
          }],
        }
      }
    });
  }

  function setUpSectionsChart(data) {
    const sectionsData = data.votesBySection.reduce( (a,e) => a = a.concat(e.sections), [] );
    const sections = sectionsData.map( e => e.section);
    const voted = sectionsData.map( e => e.voted);
    const percents = sectionsData.map(e => e.share);

    const pAxisStep = 5;
    const pAxisLabelsStep = 10;
    const pAxisMax = 100;
    var pGridColors = [];
    for(let i=0; i<=pAxisMax; i+=pAxisStep)
      pGridColors.push( i % pAxisLabelsStep === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.04)' );

    const sAxisStep = 250;
    const sAxisLabelsStep = 500;
    const sAxisMax = Math.ceil((Math.max.apply(null, voted) + sAxisLabelsStep/2) / sAxisLabelsStep) * sAxisLabelsStep;
    var sGridColors = [];
    for(let i=0; i<=sAxisMax; i+=sAxisStep)
      sGridColors.push( i % sAxisLabelsStep === 0 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.04)' );


    $('#chart-sections').attr("width", 1000 );
    $('#chart-sections').attr("height", pAxisMax * 5 );
    $('#chart-sections').css({width: '100%', height: 'auto'});

    const chartConfig = {
      type: 'bar',
      data: {
        labels: sections,
        datasets: [{}]
      },
      options: {
        responsive: false,
        tooltips: {enabled: false},
        elements: { rectangle: { borderWidth: 2 } },
        legend: { display: false },
        layout: { padding: { left: 0, right: 20, top: 20, bottom: 0 } },
        scales: {
          yAxes: [{
            type: 'linear',
            position: 'top',
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: true,
              drawBorder: true,
              lineWidth: 1,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(0, 0, 0, 0.3)',
              color: pGridColors,
            },
            ticks: {
              maxTicksLimit: 100,
              min: 0,
              fontColor: '#777',
              fontSize: 14,
              padding: 3,
            }
          }],
          xAxes: [{
            gridLines: {
              display: true,
              drawBorder: true,
              drawOnChartArea: true,
              lineWidth: 1,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(0, 0, 0, 0.3)',
              color: 'rgba(0, 0, 0, 0)',
            },
            ticks: {
              fontColor: '#333',
              fontSize: 20,
              fontStyle: 'bold',
              padding: -5,
            }
          }],
        }
      }
    };

    function configPercents() {
      const dataLabels = {
        anchor: 'end',
        align: 'end',
        offset: -1,
        labels: {
          title: null,
          value: {
            color: 'rgba(85, 85, 85, 1.0)',
            font: { size: 14.5, weight: 'bold' }
          }
        },
        formatter: v => { return midSpace + v.toFixed(1) + percentSign; }
      };

      chartConfig.options.layout.padding.left = 0;

      chartConfig.data.datasets[0].data = percents;
      chartConfig.data.datasets[0].backgroundColor = 'rgba(174, 143, 96, 0.7)';
      chartConfig.data.datasets[0].borderColor = 'rgba(174, 143, 96, 1.0)';
      chartConfig.data.datasets[0].datalabels = dataLabels;

      chartConfig.options.scales.yAxes[0].ticks.max = pAxisMax;
      chartConfig.options.scales.yAxes[0].ticks.stepSize = pAxisStep;
      chartConfig.options.scales.yAxes[0].ticks.callback = function(value /*, index, values*/) {
        return value % pAxisLabelsStep === 0 ? value + percentSign : '';
      };
    }

    function configSurfaces() {
      const dataLabels = {
        anchor: 'end',
        align: 'end',
        offset: -2,
        labels: {
          title: null,
          value: {
            font: { size: 13, weight: 'bold' },
            color: 'rgba(85, 85, 85, 1.0)'
          }
        },
        formatter: v => { return ' ' + v.toFixed(0) + metersSign; }
      };

      chartConfig.options.layout.padding.left = 7;

      chartConfig.data.datasets[0].data = voted;
      chartConfig.data.datasets[0].backgroundColor = 'rgba(112, 96, 174, 0.7)';
      chartConfig.data.datasets[0].borderColor = 'rgba(112, 96, 174, 1.0)';
      chartConfig.data.datasets[0].datalabels = dataLabels;

      chartConfig.options.scales.yAxes[0].ticks.max = sAxisMax;
      chartConfig.options.scales.yAxes[0].ticks.stepSize = sAxisStep;
      chartConfig.options.scales.yAxes[0].ticks.callback = function(value /*, index, values*/) {
        return value % sAxisLabelsStep === 0 ? value : '';
      };
    }

    configPercents();
    const chart = new Chart($('#chart-sections'), chartConfig);

    $("#chart-switch").change(function() {
      if(this.checked)
        configPercents();
      else
        configSurfaces();
      chart.update();
    });
  }

  function init() {
    $.getJSON('/data/statistics.json', function(data) {
      $('#content').show();
      setUpDaysChart(data);
      setUpSectionsChart(data);
    });
  }

  statistics.init = init;
})();
