/* global format */
if( !window.format ) window.format = {};

(function() {
  function _number(v) {
    return new Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(v).replace(String.fromCharCode(160), '&thinsp;');
  }

  function number(v) {
    return '<nobr>' + _number(v) + '</nobr>';
  }

  function surface(v) {
    return '<nobr>' + _number(v) + ' кв.м</nobr>';
  }

  function votes(v) {
    return '<nobr>' + _number(v) + '</nobr> голосов';
  }

  function share(v) {
    return '<nobr>' + _number(v * 100) + '&hairsp;%</nobr>';
  }

  format.number = number;
  format.surface = surface;
  format.votes = votes;
  format.share = share;
})();
