/* global results, format */
if( !window.results ) window.results = {};

(function() {

  function option(o, s) {
    return '' +
      '<td class="option">' +
        '<div class="surface">' + format.surface(o.surface) + '</div>' +
        '<div class="percent">' + format.share(o.surface / s) + '</div>' +
        '<div class="count">' + o.count + ' чел.</div>' +
      '</td>';
  }

  function question(q, s) {
    return option(q.for, s) + option(q.against, s) + option(q.abstained, s);
  }

  function init() {
    $.getJSON('/data/results.json', function(data) {
      $('#votedShare').html(format.share(data.voted.surface / data.total.surface));
      $('#votedSurface').html(format.number(data.voted.surface));
      $('#totalSurface').html(format.number(data.total.surface));
      $('#votedCount').text(data.voted.count);
      $('#totalCount').text(data.total.count);

      $("#votes tbody tr").each( (i,e) =>
        $(e).append(question(data.voted.questions[i], data.voted.surface)) );

      $("#votes thead th").data("sorter", false);
      $("#votes").tablesorter({});

      $('#content').show();
    });
  }

  results.init = init;
})();
