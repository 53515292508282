/* global votes, format */
if( !window.votes ) window.votes = {};

(function() {

  function qCell(v) {
    let str = '';
    v = Number(v);
    if(v === 0) str = '<td class="question abstained">ВОЗ.</td>';
    if(v === 1) str = '<td class="question for">ЗА</td>';
    if(v === 2) str = '<td class="question against">ПР.</td>';
    return str;
  }

  function qCells(o) {
    return qCell(o.q1) + qCell(o.q2) + qCell(o.q3) + qCell(o.q4) + qCell(o.q5) + qCell(o.q6) + qCell(o.q7) + qCell(o.q8);
  }

  function codeCell(o) {
    return '<td class="code">' + o.code + '</td>';
  }

  function formatDate(date) {
    return date ? date.split("-").reverse().join(".") : '';
  }

  function dtdCell(o) {
    return '<td class="date">' + formatDate(o.dtd) + '</td>';
  }

  function rcvdCell(o) {
    return '<td>' + formatDate(o.rcvd) + '</td>';
  }

  function validateCode(e) {
    var charCode = (e.which) ? e.which : e.keyCode;
    if (charCode >= 48 && charCode <= 57)
        return true;
    return false;
  }

  function init() {
    $.getJSON('/data/votes.json', data => {
      $('#votedShare').html(format.share(data.voted.surface / data.total.surface));
      $('#votedSurface').html(format.number(data.voted.surface));
      $('#totalSurface').html(format.number(data.total.surface));
      $('#votedCount').text(data.voted.count);
      $('#totalCount').text(data.total.count);

      var rows = '';
      $.each(data.voted.forms, (i,f) => {
        rows += '<tr>' + codeCell(f) + dtdCell(f) + qCells(f) + '</tr>';
      });
      $('#votes tbody').append(rows);

      $('#votes thead th').data("sorter", false);
      $('#votes').tablesorter({
        widgets: ["filter"],
        widgetOptions : {
          filter_external: '#search input',
          filter_startsWith: true,
          filter_searchDelay: 0,
        }
      });

      $('#votes').bind('filterEnd', () => {
        let rows = $('#votes tbody tr:not(.filtered)');

        if(rows.length === 0)
          $('#votes').addClass('no-match');
        else
          $('#votes').removeClass('no-match');

        if(rows.length === 1) {
          $('.on-mobile #search input').blur();
          rows.addClass('highlight');
        }
        else
          rows.removeClass('highlight');
      });

      $('#content').show();
    });
  }

  votes.formatDate = formatDate;
  votes.codeCell = codeCell;
  votes.dtdCell = dtdCell;
  votes.rcvdCell = rcvdCell;
  votes.validateCode = validateCode;
  votes.init = init;
})();
